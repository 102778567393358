import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import * as $ from 'jquery';
import { PwDropdownService, PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';
import { MatSnackBar } from '@angular/material/snack-bar';
import X2JS from 'x2js';

@Component({
  selector: 'tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss']
})
export class TrackerComponent implements OnInit {
  agentData: any;
  deviceTypeData: any;
  titleData: any;
  campaignType: any;
  isSuccess: boolean = false;
  isError: boolean = false;
  message: string = '';
  leadForm: FormGroup;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  validationMessages: any;
  errorCount: number;
  error: boolean;
  showMessage: boolean;
  API_URL = environment.tracker.trackerSubmitterApi;
  success: boolean = false;
  commentsDefaultValue: string = 'Positive response, First for Women';
  _theme: PwThemeModel;
  REFERENCENO: Number = 0;
  PARTNERUNIQUEREFNO: string = '?';
  TRANSACTION_TYPE: string = '1';
  NOTIFICATIONTYPE: string = '0';
  TRANSMISSIONTYPE: string = '0';
  SOURCEID: string = environment.tracker.sourceId;
  User: string = environment.tracker.trackerUsername;
  Password: string = environment.tracker.trackerPassword;

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private firebase: PwFirebaseService,
    private theme: PwThemeingService,
    private snackBar: MatSnackBar,
    private dropdownService: PwDropdownService) {

    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };

    this.deviceTypeData = {
      name: 'DEVICETYPE',
      label: 'Select Device:',
      options: [
        {
          name: 'Recover',
          label: 'Recover',
          value: '38',
          selected: true
        },
        {
          name: 'Protect',
          label: 'Protect',
          value: '39'
        },
        {
          name: 'Care',
          label: 'Care',
          value: '40'
        },
      ],
      placeholder: 'Select Device'
    };

    this.titleData = {
      name: 'TITLE',
      label: 'Select Title:',
      options: [
        {
          name: 'Mr',
          label: 'Mr',
          value: 'Mr',
          selected: true
        },
        {
          name: 'Mrs',
          label: 'Mrs',
          value: 'Mrs'
        },
        {
          name: 'Ms',
          label: 'Ms',
          value: 'Ms'
        },
        {
          name: 'Miss',
          label: 'Miss',
          value: 'Miss'
        },
        {
          name: 'Other',
          label: 'Other',
          value: 'Other'
        },
      ],
      placeholder: 'Select Title'
    };
  }

  ngOnInit() {
    this.createForm();

    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray = JSON.parse(names);
      namesArray.map((nameArray, index) => {
        if (index === 0) {
          this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0], selected: true};
        } else {
          this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
        }
      });
    });

    this._theme = new PwThemeModel({
      borderColor: '#FFCB09',
      textColor: '#000',
      buttonBgColor: '#FFCB09',
      buttonTextColor: '#fff',
      buttonBorderColor: '#FFCB09',
      errorColor: 'red',
      labelColor: '#000',
      dropdownOptionBgColor: '#FFCB09',
      dropdownOptionTextColor: '#fff'
    })
    this.theme.setTheme(this._theme);
  }

  createForm() {
    this.leadForm = this.formBuilder.group({
      TITLE: ['', Validators.required],
      FIRSTNAME: ['', Validators.required],
      SURNAME: ['', Validators.required],
      DEVICETYPE: ['', Validators.required],
      CELLNO: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
      IDNUMBER: ['', [Validators.minLength(13), Validators.maxLength(13)]],
    });

    this.validationMessages = {
      TITLE: {
        'required': 'Please select a valid title'
      },
      FIRSTNAME: {
        'required': 'Please enter a valid name'
      },
      SURNAME: {
        'required': 'Please enter a valid surnname'
      },
      DEVICETYPE: {
        'required': 'Please select a device type'
      },
      CELLNO: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      IDNUMBER: {
        'required': 'Please enter a valid ID number',
        'maxlength': 'Please enter a number not greater than 13 charaters',
        'minlength': 'Please enter a number not less than 13 charaters'
      }
    }

    this.formStatus['formErrors'] = {
      FIRSTNAME: '',
      SURNAME: '',
      CELLNO: '',
      IDNUMBER: '',
      TITLE: '',
      DEVICETYPE: '',
    }

    this.leadForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    });
  }

  submitLead() {
    if (this.leadForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let prefix = 'Gilela-';
    let FIRSTNAME = this.leadForm.controls['FIRSTNAME'].value;
    let SURNAME = this.leadForm.controls['SURNAME'].value;
    let CELLNO = this.leadForm.controls['CELLNO'].value;
    let agentName = this.leadForm.controls['agentName'].value;
    let IDNUMBER = this.leadForm.controls['IDNUMBER'].value ? this.leadForm.controls['IDNUMBER'].value : '';
    let TITLE = this.leadForm.controls['TITLE'].value;
    let DEVICETYPE = this.leadForm.controls['DEVICETYPE'].value;
    let INITIAL = FIRSTNAME.substr(0,1).toUpperCase() + SURNAME.substr(0,1).toUpperCase();
    let campaignName = 'tracker';
    // this.PARTNERUNIQUEREFNO = (prefix + IDNUMBER + this.getOptinDate(false)).substr(0,5);
    let ref = Date.now() * Math.random();
    let intRef = parseInt(ref.toString().substring(0, 8), 10);
    this.REFERENCENO = intRef;
    this.PARTNERUNIQUEREFNO = prefix + this.REFERENCENO;

    console.log(ref)
    console.log(intRef)

    this.firebase.saveRecord({
      id: intRef.toString(),
      INITIAL: INITIAL,
      IDNUMBER: IDNUMBER,
      FIRSTNAME: FIRSTNAME,
      SURNAME: SURNAME,
      CELLNO: CELLNO,
      DEVICETYPE: DEVICETYPE.label,
      PARTNERUNIQUEREFNO: this.PARTNERUNIQUEREFNO.toString(),
      REFERENCENO: this.REFERENCENO.toString(),
      SOURCEID: this.SOURCEID,
      NOTIFICATIONTYPE: this.NOTIFICATIONTYPE,
      agentName: agentName.label,
      campaignName: campaignName,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then((e) => {
      this.firebase.updateCount('leads', campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    }).catch((err) => {
      console.log(err);
    });

    $.post(this.API_URL, {
        User: this.User,
        Password: this.Password,
        TITLE: TITLE.label,
        INITIALS: INITIAL,
        FIRSTNAME: FIRSTNAME,
        SURNAME: SURNAME,
        CELLNO: CELLNO,
        IDNUMBER: IDNUMBER,
        DEVICETYPE: DEVICETYPE.value,
        SOURCEID: this.SOURCEID,
        NOTIFICATIONTYPE: this.NOTIFICATIONTYPE,
        TRANSACTIONTYPE: this.TRANSACTION_TYPE,
        PARTNERUNIQUEREFNO: this.PARTNERUNIQUEREFNO,
        REFERENCENO: this.REFERENCENO,
        TRANSMISSIONTYPE: this.TRANSMISSIONTYPE,

    }, (response, status) => {
      this.error = true;
      this.showMessage = true;

      var x2js = new X2JS();
      var respnseObj: any = x2js.xml2js(response);

      console.log(respnseObj);

      if (status === "success") {
        if (respnseObj.Envelope.Body.hasOwnProperty('SendToTrackerResponse')) {
          this.message = respnseObj.Envelope.Body.SendToTrackerResponse.SendToTrackerResult;
        } else {
          if (respnseObj.Envelope.Body.hasOwnProperty('Fault')) {
            this.message = respnseObj.Envelope.Body.Fault.faultstring;
          }
        }

        this.googleApiIntegrationService.writeToTrackerSheet({
          firstname: FIRSTNAME,
          surname: SURNAME,
          phone: CELLNO,
          idNumber: IDNUMBER,
          agentName: agentName.label,
          title: TITLE.label,
          initial: INITIAL,
          deviceType: DEVICETYPE.label,
          referenceNo: this.REFERENCENO,
          partnerUniqueRefNo: this.PARTNERUNIQUEREFNO.toString(),
          status: this.message
        });

        this.snackBar.open(this.message, 'Ok!', {
          verticalPosition: "bottom", // Allowed values are  'top' | 'bottom'
          horizontalPosition: "center" // Allowed values are 'start' | 'center' | 'end' | 'left' | 'right'
        });

        this.resetForm();
      } else {
        if (status === "error") {
          this.message = "Oops, an error has occured. Please try again."
          this.isError = true;
          this.isSuccess = false;

          this.googleApiIntegrationService.writeToTrackerSheet({
            firstname: FIRSTNAME,
            surname: SURNAME,
            phone: CELLNO,
            IDNUMBER: IDNUMBER,
            title: TITLE.label,
            initial: INITIAL,
            agentName: agentName.label,
            deviceType: DEVICETYPE.value,
            status: this.message,
            referenceNo: this.REFERENCENO,
            partnerUniqueRefNo: this.PARTNERUNIQUEREFNO.toString(),
          });
        }
      }
    }, "html");
  }

  resetForm() {
    this.dropdownService.resetDropdown('TITLE');
    this.dropdownService.resetDropdown('DEVICETYPE');
    this.dropdownService.resetDropdown('agentName');
    this.leadForm.reset();
  }

  formIsValid() {
    if (!this.leadForm) {
      return;
    }

    const form = this.leadForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        // tslint:disable-next-line:forin

        for (const key in control.errors) {

          this.formStatus['formErrors'][field] += messages[key] + ' ';

          this.errorCount++;
        }

      }
    }
  }

  sanitizeString(str){
    str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
    return str.trim();
  }

  getOptinDate(hasDivider?: boolean, divider?: string) : string {
    divider = divider ? divider : '/';
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate;
    if (hasDivider) {
      strDate = dd + divider + mm + divider + yyyy;
    } else {
      strDate = dd + mm + yyyy;
    }
    return strDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }

}

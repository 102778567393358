<div class="landing-container">
    <div class="header">
        <div class="header__heading">
            Welcome to Better Life Insurance
            <span class="header__sub-heading">
                Select a campaign to continue.
            </span>
        </div>
    </div>

    <div class="body">

      <!-- <a href="#/1life" class="client-tile__container">
        <div class="client-tile__hero-image client-tile__hero-image--life"></div>
        <div class="client-tile__description"> 1 Life </div>
      </a> -->

      <a href="#/budget-insurance" class="client-tile__container">
        <div class="client-tile__hero-image client-tile__hero-image--budget"></div>
        <div class="client-tile__description">
            <span> Budget </span>
        </div>
      </a>

      <a href="#/first-for-women" class="client-tile__container">
          <div class="client-tile__hero-image client-tile__hero-image--firstforwomen"></div>
          <div class="client-tile__description">
              <span> First for Women </span>
          </div>
      </a>

      <a href="#/virseker" class="client-tile__container">
        <div class="client-tile__hero-image client-tile__hero-image--dial-direcsst"></div>
        <div class="client-tile__description">
            <span> Virseker </span>
        </div>
      </a>

        <!-- <a href="#/cartrack" class="client-tile__container">
            <div class="client-tile__hero-image client-tile__hero-image--cartrack"></div>
            <div class="client-tile__description"> Cartrack </div>
        </a> -->

        <!-- <a href="#/kingprice" class="client-tile__container">
            <div class="client-tile__hero-image client-tile__hero-image--kingprice"></div>
            <div class="client-tile__description">
                <span> Kingprice </span>
             </div>
        </a>-->

        <a href="#/auto-and-general" class="client-tile__container">
            <div class="client-tile__hero-image client-tile__hero-image--ang"></div>
            <div class="client-tile__description">
                <span> Auto & General </span>
             </div>
        </a>

        <a href="#/dial-direct" class="client-tile__container">
            <div class="client-tile__hero-image client-tile__hero-image--dial-direct"></div>
            <div class="client-tile__description">
                <span> Dial Direct </span>
            </div>
        </a>
    </div>
</div>

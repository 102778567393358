import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing-module/app-routing-module.module';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { PwButtonModule, PwInputModule, PwDropdownModule, PwTextAreaModule, PwCampaignTemplateModule } from '@kaleva-media/purple-water-next';
import { FlowSubmitLeadComponent } from './flow-submit-lead/flow-submit-lead.component';
import { FlowSubmitHollardLeadComponent } from './flow-submit-hollard-lead/flow-submit-hollard-lead.component';
import { FlowSubmitKingpriceLeadComponent } from './flow-submit-kingprice-lead/flow-submit-kingprice-lead.component';
import { GoogleApiIntegrationService } from './google-api-integration.service';
import { FlowBudgetInsuranceComponent } from './flow-budget-insurance/flow-budget-insurance.component';
import { FlowLandingPageComponent } from './flow-landing-page/flow-landing-page.component';
import { CarLoanComponent } from './car-loan/car-loan.component';
import { FvLoansComponent } from './fv-loans/fv-loans.component';
import { FvFuneralComponent } from './fv-funeral/fv-funeral.component';
import { FlowSubmitAssupolLeadComponent } from './flow-submit-assupol-lead/flow-submit-assupol-lead.component';
import { FormServiceService } from './form-service.service';
import { AtaOneLifeComponent } from './ata-one-life/ata-one-life.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CookieService } from 'ngx-cookie-service';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { FlowSubmitClienteleLeadComponent } from './flow-submit-clientele-lead/flow-submit-clientele-lead.component';
import { AutoAndGeneralComponent } from './auto-and-general/auto-and-general.component';
import { BudgetInsuranceComponent } from './budget-insurance/budget-insurance.component';
import { FirstForWomanComponent } from './first-for-woman/first-for-woman.component';
import { DialDirectComponent } from './dial-direct/dial-direct.component';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTreeModule } from '@angular/material/tree';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CampaignOverviewComponent } from './reporting/campaign-overview/campaign-overview.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TrackerComponent } from './tracker/tracker.component';
import { CarTrackComponent } from './car-track/car-track.component';


@NgModule({
  declarations: [
    AppComponent,
    FlowSubmitLeadComponent,
    FlowSubmitHollardLeadComponent,
    FlowSubmitKingpriceLeadComponent,
    FlowBudgetInsuranceComponent,
    FlowLandingPageComponent,
    CarLoanComponent,
    FvLoansComponent,
    FvFuneralComponent,
    FlowSubmitAssupolLeadComponent,
    AtaOneLifeComponent,
    DialogModalComponent,
    FlowSubmitClienteleLeadComponent,
    AutoAndGeneralComponent,
    BudgetInsuranceComponent,
    FirstForWomanComponent,
    DialDirectComponent,
    CampaignOverviewComponent,
    TrackerComponent,
    CarTrackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatSidenavModule,
    MatToolbarModule,
    NgChartsModule,
    MatDialogModule,
    PwButtonModule,
    PwInputModule,
    PwDropdownModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    MatBadgeModule,
    MatTreeModule,
    MatSnackBarModule,
    PwTextAreaModule,
    NgxChartsModule,
    PwCampaignTemplateModule
  ],
  providers: [GoogleApiIntegrationService, FormServiceService, CookieService, ThemeService],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { GoogleApiIntegrationService } from '../google-api-integration.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { PwFirebaseService } from '@kaleva-media/purple-water-next';

@Component({
  selector: 'car-track',
  templateUrl: './car-track.component.html',
  styleUrls: ['./car-track.component.css']
})
export class CarTrackComponent implements OnInit {
  API_URL = environment.cartrack.apiUrl;
  TOKEN: string = environment.cartrack.token;
  CAMPAIGN_UID: string = environment.cartrack.campaignUid;

  success: boolean = false
  budgetInsuranceForm: FormGroup;
  errorCount: number;
  agentData: any;
  validationMessages: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  };

  constructor(
    private formBuilder: FormBuilder,
    private googleApiIntegrationService: GoogleApiIntegrationService,
    private firebase: PwFirebaseService) {
    this.agentData = {
      name: 'agentName',
      label: 'Agent Name:',
      options: [],
      placeholder: 'Select Name'
    };
  }

  ngOnInit() {
    this.createForm();

    this.googleApiIntegrationService.reatAtNames();
    this.googleApiIntegrationService.names.subscribe((names) => {
      let namesArray;

      try {
        namesArray = JSON.parse(names);
        namesArray.map((nameArray, index) => {
          this.agentData.options[index] = {name: 'agent-' + index, label: nameArray[0]};
        });
        console.log(names);
      } catch(e) {
          namesArray = [
            "Names unavailable"
          ];

          namesArray.map((nameArray, index) => {
            this.agentData.options[index] = {name: 'agent-' + index, label: namesArray[index]};
          });

          console.error(names);
      }

      console.log(this.agentData)
    });
  }

  createForm() {
    this.budgetInsuranceForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      telephone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      agentName: '',
      email: ['', [Validators.required, Validators.email]]
    });

    this.validationMessages = {
      name: {
        'required': 'Please enter a valid name'
      },
      surname: {
        'required': 'Please enter a valid surnname'
      },
      telephone: {
        'required': 'Please enter a valid number',
        'maxlength': 'Please enter a number not greater than 10 charaters',
        'minlength': 'Please enter a number not less than 10 charaters'
      },
      email: {
        'required': 'Please select a valid email address',
        'email': 'Please select a valid email address'
      }
    }

    this.formStatus['formErrors'] = {
      name: '',
      surname: '',
      telephone: '',
      email: ''
    }

    this.budgetInsuranceForm.valueChanges.subscribe((data) => {
      this.formIsValid();
    })
  }

  formIsValid() {
    if (!this.budgetInsuranceForm) {
      return;
    }

    const form = this.budgetInsuranceForm;
    this.errorCount = 0;

    // tslint:disable-next-line:forin
    for (const field in this.formStatus['formErrors']) {
      // clear previous error message (if any)
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];

        for (const key in control.errors) {
          this.formStatus['formErrors'][field] += messages[key] + ' ';
          this.errorCount++;
        }

      }
    }
  }

  getOptinDate() {
    let currentDate = new Date();
    let optinDate = moment(currentDate).format('DD/MM/YYYY hh:mm:ss')

    return optinDate;
  }

  getOptinDateObj() : any {
    var today = new Date();
    let dateObj = {};
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let strDate = dd + '/' + mm + '/' + yyyy;
    dateObj['day'] = dd;
    dateObj['month'] = mm;
    dateObj['year'] = yyyy;
    dateObj['date'] = strDate;
    return dateObj;
  }

  submitLead() {
    if (this.budgetInsuranceForm.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    let name = this.budgetInsuranceForm.controls['name'].value;
    let surname = this.budgetInsuranceForm.controls['surname'].value;
    let telephone = this.budgetInsuranceForm.controls['telephone'].value;
    let agentName = this.budgetInsuranceForm.controls['agentName'].value;
    let email = this.budgetInsuranceForm.controls['email'].value;
    let campaignName = 'cartrack';
    let optinDate = this.getOptinDate();

    this.success = true;

    this.firebase.saveRecord({
      id: telephone,
      name: name,
      surname: surname,
      phone: telephone,
      campaignName: campaignName,
      agentName: agentName,
      date: this.getOptinDate(),
      day: this.getOptinDateObj().day,
      month: this.getOptinDateObj().month,
      year: this.getOptinDateObj().year
    }, 'leads').then(() => {
      this.firebase.updateCount('leads', campaignName).then(() => {
        this.firebase.updateCount('leads');
      });
    });

    $.post(this.API_URL, {
      FirstName: name,
      LastName: surname,
      CellNumber: telephone,
      // Optindate: optinDate, // date was causing an error on the api.
      Email: email,
      Token: this.TOKEN,
      campaignUid: this.CAMPAIGN_UID
    }, (response, status) => {
      let resp = JSON.parse(response);
      console.log(resp);

      if (status === "success") {
        this.success = true;
        this.googleApiIntegrationService.writeToFVCarTrackSheet({
          firstName: name,
          surname: surname,
          phone: telephone,
          agentName: agentName.label,
          status: resp.status,
          reason: resp.error || '',
          // location: 'DBN',
          leadUid: resp.data.contact_details[0].lead_uid
        });
      } else {
        if (status === "error") {

          this.googleApiIntegrationService.writeToFVCarTrackSheet({
            firstName: name,
            surname: surname,
            phone: telephone,
            agentName: agentName.label,
            status: resp.status,
            reason: resp.error || '',
            // location: 'DBN',
            leadUid: resp.data.contact_details[0].lead_uid || ''
          });
        }
      }
    }, "json");
  }

  resetForm() {
    this.budgetInsuranceForm.reset();
    this.success = false;
  }

}
